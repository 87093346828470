/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Calendar, ExternalLink } from "react-feather";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import parse from "html-react-parser";

const ArticleGridLayout = (props) => {
  console.log(props, "props");
  const { target, image, title, date, excerpt, categories_slugs } = props;
  const categoryUrl = "/" + categories_slugs?.[0]?.slug;
  const subCategoryUrl = "/" + categories_slugs?.[0]?.slug + "/" + categories_slugs?.[1]?.slug;
  return (
    <ArticleGridLayoutWrapper className="h-100">
      <Card className="h-100 border-0 ">
        <div className="item-wrapper d-flex flex-column h-100">
          <div className="featured-image-wrapper">
            <img src={image} alt="article image" className="featured-image-thumb" width={"100%"} />
          </div>

          <CardBody className="d-flex flex-column h-100">
            <Link to={subCategoryUrl + "/" + target}>
              <h3 className="weight-700 title mb-3">{title}</h3>
            </Link>

            {excerpt && <p className="excerpt mb-4">{excerpt ? parse(`${excerpt}`) : ""}</p>}

            <Button
              color="secondary"
              tag={"a"}
              href={subCategoryUrl + "/" + target}
              size="sm"
              outline
              className="text-uppercase px-5 mt-auto align-self-start"
            >
              Learn More
            </Button>
          </CardBody>
        </div>
      </Card>
    </ArticleGridLayoutWrapper>
  );
};

export default ArticleGridLayout;

export const ArticleGridLayoutWrapper = styled.div`
  box-shadow: 0px 20px 24px -6px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  overflow: hidden;
  border-radius: 20px;

  .title {
    font-size: 24px;
  }

  .excerpt {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    p {
      margin-bottom: 0;
    }
  }

  @media (min-width: 768.01px) {
    .featured-image-wrapper {
      flex: 0 0 220px;
    }

    .featured-image-thumb {
      aspect-ratio: 16/10;
      object-fit: cover;
    }

    .card-body {
      padding: 40px;
    }
  }
`;
