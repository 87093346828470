export const statesWithLink = () => {
return [
  {
    "label": "Alabama",
    "value": "https://albop.com/consumer/"
  },
  {
    "label": "Alaska",
    "value": "https://www.commerce.alaska.gov/web/ins/PBMAppeals.aspx#:~:text=How%20can%20I%20request%20a,or%20907%2D269%2D7900."
  },
  {
    "label": "Arizona",
    "value": "https://pharmacy.az.gov/file-complaint#:~:text=Receipt%20of%20the%20Complaint,of%20pharmacy%20laws%20or%20rules."
  },
  {
    "label": "Arkansas",
    "value": "https://healthy.arkansas.gov/boards-commissions/boards/pharmacy-arkansas-label-board/asbp-complaints/"
  },
  {
    "label": "California",
    "value": "https://www.pharmacy.ca.gov/consumers/complaint_info.shtml"
  },
  {
    "label": "Colorado",
    "value": "https://dpo.colorado.gov/FileComplaint"
  },
  {
    "label": "Connecticut",
    "value": "https://portal.ct.gov/dcp/file-a-complaint"
  },
  {
    "label": "Delaware",
    "value": "https://dpr.delaware.gov/boards/investigativeunit/filecomplaint/"
  },
  {
    "label": "District of Columbia",
    "value": "1. https://dchealth.dc.gov/service/file-complaint-against-health-professional-or-facility\n\n2. chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/https://dchealth.dc.gov/sites/default/files/dc/sites/doh/publication/attachments/Complaint%20Form%202018.pdf"
  },
  {
    "label": "Florida",
    "value": "https://www.flhealthcomplaint.gov/home"
  },
  {
    "label": "Georgia",
    "value": "https://gbp.georgia.gov/georgia-professional-licensure-complaint-form"
  },
  {
    "label": "Hawaii",
    "value": "https://cca.hawaii.gov/rico/file/"
  },
  {
    "label": "Idaho",
    "value": "https://edopl.idaho.gov/OnlineServices/_/#1"
  },
  {
    "label": "Illinois",
    "value": "https://idfpr.illinois.gov/admin/dpr/dprcomplaint.html"
  },
  {
    "label": "Indiana",
    "value": "https://www.in.gov/pla/file-a-complaint/"
  },
  {
    "label": "Iowa",
    "value": "https://labelofiowa.seamlessdocs.com/f/IBPE_Complaint_Form"
  },
  {
    "label": "Kansas",
    "value": "https://www.pharmacy.ks.gov/forms-faqs/complaints"
  },
  {
    "label": "Kentucky",
    "value": "https://insurance.ky.gov/ppc/newstatic_info.aspx?static_id=1"
  },
  {
    "label": "Louisiana",
    "value": "https://www.ldi.la.gov/OnlineServices/ConsumerComplaintForm/Complaints/IndexPbm"
  },
  {
    "label": "Maine",
    "value": "https://maine.gov/pfr/insurance/consumers/file-a-complaint-dispute"
  },
  {
    "label": "Maryland",
    "value": "https://insurance.maryland.gov/consumer/pages/fileacomplaint.aspx"
  },
  {
    "label": "Massachusetts",
    "value": "https://www.mass.gov/how-to/filing-an-insurance-complaint"
  },
  {
    "label": "Michigan",
    "value": "https://www.michigan.gov/difs/consumers/complaint"
  },
  {
    "label": "Minnesota",
    "value": "https://mn.gov/boards/pharmacy/public/pharmacyrelatedcomplaints.jsp"
  },
  {
    "label": "Mississippi",
    "value": "https://www.mbp.ms.gov/pharmacy-benefit-managers-pbm"
  },
  {
    "label": "Missouri",
    "value": "https://pr.mo.gov/pharmacists-file-a-complaint2.asp"
  },
  {
    "label": "Montana",
    "value": "https://bsd.dli.mt.gov/filing-complaint"
  },
  {
    "label": "Nebraska",
    "value": "https://phcmplnt-dhhs.ne.gov/InsurerComplaint"
  },
  {
    "label": "Nevada",
    "value": "https://bop.nv.gov/services/Complaint/"
  },
  {
    "label": "New Hampshire",
    "value": "https://www.oplc.nh.gov/report-non-compliance"
  },
  {
    "label": "New Jersey",
    "value": "https://www.nj.gov/dobi/consumer.htm#insurance"
  },
  {
    "label": "New Mexico",
    "value": "https://www.osi.label.nm.us/pages/misc/pbm-complaint"
  },
  {
    "label": "New York",
    "value": "https://www.dfs.ny.gov/complaint"
  },
  {
    "label": "North Carolina",
    "value": "https://www.ncdoi.gov/consumers/health-insurance/providerpharmacy-complaints-against-health-planspbms"
  },
  {
    "label": "North Dakota",
    "value": "https://www.insurance.nd.gov/consumers/complaints"
  },
  {
    "label": "Ohio",
    "value": "https://gateway.insurance.ohio.gov/UI/ODI.CS.Public.UI/PBMComplaint.mvc/DisplayComplaintForm"
  },
  {
    "label": "Oklahoma",
    "value": "https://oklahoma.gov/pharmacy/complaints.html"
  },
  {
    "label": "Oregon",
    "value": "https://dfr.oregon.gov/help/complaints-licenses/Pages/file-complaint.aspx"
  },
  {
    "label": "Pennsylvania",
    "value": "https://www.insurance.pa.gov/Consumers/Pages/default.aspx"
  },
  {
    "label": "Rhode Island",
    "value": "chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/https://dbr.ri.gov/sites/g/files/xkgbur696/files/documents/divisions/insurance/life_accident_health/complaint_form.PDF"
  },
  {
    "label": "South Carolina",
    "value": "https://www.doi.sc.gov/FormCenter/Pharmacy-Benefits-Manager-PBMs-15/PBM-Complaint-Form-68"
  },
  {
    "label": "South Dakota",
    "value": "https://dlr.sd.gov/insurance/doi_complaint.aspx"
  },
  {
    "label": "Tennessee",
    "value": "https://www.tn.gov/commerce/insurance/pbm.html"
  },
  {
    "label": "Texas",
    "value": "https://www.tdi.texas.gov/hprovider/pharmacy.html"
  },
  {
    "label": "Utah",
    "value": "https://insurance.utah.gov/complaints/"
  },
  {
    "label": "Vermont",
    "value": "https://dfr.vermont.gov/consumers/file-complaint/insurance/insurance-complaints"
  },
  {
    "label": "Virginia",
    "value": "https://sbs.naic.org/solar-web/pages/public/onlineComplaintForm/onlineComplaintForm.jsf?label=VT&dswid=7833"
  },
  {
    "label": "Washington",
    "value": "https://www.insurance.wa.gov/file-complaint-or-check-your-complaint-status"
  },
  {
    "label": "West Virginia",
    "value": "chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/https://www.wvinsurance.gov/Portals/0/pdf/PBM%20Complaint%20Form.pdf?ver=2023-07-10-125848-213"
  },
  {
    "label": "Wisconsin",
    "value": "https://ociaccess.oci.wi.gov/complaints/public/receiveComplaint;jsessionid=F6FA7F04D514D5233AA113441BC42536.webpub1-legacy-blue?execution=e1s1"
  },
  {
    "label": "Wyoming",
    "value": "https://gov.sircon.com/consumerPortalLogin.do?method=initProcess&request.authorization.token=nmZeFfvCYEXI09GIR4awhHFRhwc6ukL8"
  }
]
}