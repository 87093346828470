import moment from "moment"
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { State, City } from 'country-state-city'
import { Organizations } from "aws-sdk"
const MySwal = withReactContent(Swal)

export const DateConfig = (date) => {
   let formateddate = moment(new Date(date).toLocaleString("en-US", {timeZone: "America/Virgin"})).format("MM/DD/YYYY");
   return  formateddate;
}

export const TimeConfig = (date) => {
   let formateddate = moment(new Date(date).toLocaleString("en-US", {timeZone: "America/Virgin"})).format("h:mm A");
   return  formateddate;
}

export const DateTimeConfig = (date) => {
   let formateddate = moment(new Date(date).toLocaleString("en-US", {timeZone: "America/Virgin"})).format("MM/DD/YYYY h:mm A");
   return  formateddate;
}

// Swal alert 
// Loader
export const swalalertWithMsg = (val,msg) => {
   if (val == true) {
      MySwal.fire({
         title: msg,
        //  text: msg,
         // html: 'File Uploading',
         allowOutsideClick: false,
         onBeforeOpen() {
            Swal.showLoading()
         }
      })
   } else {
      Swal.close();
   }
}


export const valSwalFailure = (title,msg) => {
   MySwal.fire({
      icon: 'error',
      title: title,
      text: msg,
      customClass: {
         confirmButton: 'btn btn-danger'
      }
   })
}

// Delete 
export const valSwalDelete = async() => {
   let retData = '';
  await MySwal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this record?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      },
      buttonsStyling: false
    }).then(function (result) {
         retData = result.isConfirmed;
    });

    return retData;
}

//Success
export const valSwalSuccess = (title, msg) => {
   MySwal.fire({
      icon: 'success',
      title: title,
      text: msg,
      customClass: {
         confirmButton: 'btn btn-success'
      }
   })
}

//Confirm message
export const valSwalConfirm = async(title,text,confirmBtnText) => {
   let retData = '';
  await MySwal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmBtnText,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      },
      buttonsStyling: false
    }).then(function (result) {
         retData = result.isConfirmed;
    });

    return retData;
}

// Initative
export const allInitiative = ['CAN','COPA','COA','CPAN','LAWS','EOM','OMH','FELLOWS']
export const adminUsers = [
   'auth0|63e54c0e893cae0011f95bdc',
   'auth0|63efb4e940bc54e30ce73c95',
   'auth0|63efb4e940bc54e30ce73cad',
   'auth0|63efb4eb40bc54e30ce7403f',
   'auth0|642c7c3b87337f0011356f2f',
   'auth0|643d2dd45d52b10011be828d',
   'auth0|63efb4e940bc54e30ce73c5f',
]


export const getAllStateByCountry = (method) => {
   var states = State.getStatesOfCountry('US')
  if(method == 'raw'){
    return states
  }else{
    var stateOption = [];
    states.map((state)=>{
      stateOption.push({label:state.name,value:state.name,isoCode:state.isoCode,countryCode:state.countryCode})
    })
  return stateOption
  }
  }
  
  export const getAllCityByCountryState = (method,countryCode,stateCode) => {
   var cities = City.getCitiesOfState(countryCode,stateCode)
   if(method == 'raw'){
    return cities
  }else{
   var cityOption = [];
        cities.map((city)=>{
          cityOption.push({label:city.name,value:city.name,stateCode:city.stateCode,countryCode:city.countryCode})
        })
        console.log(method,countryCode,stateCode,'cityOption')
    return cityOption
      }
  } 

  export const organizationTypeOption = () => {
   var orgType = [
   'Broker/Consulting Agency',
   'Diagnostic, laboratory, testing or device industry',
   'Employer (Individual)',
   'Employer health group or business coalition',
   'Government or government agency',
   'Group Purchasing Organization (GPO) or distributor',
   'Health insurer or payer',
   'Hospital or health system',
   'Media or Press',
   'Non-profit association or advocacy group',
   'Individual advocate: Patient, survivor, caregiver, or family member',
   'Pharmaceutical or biotech manufacturer',
   'Pharmacy benefit manager (PBM)',
   'Private or independent oncology practice – hospital affiliated, owned, PSA, or similar',
   'Private or independent oncology practice – physician-owned cancer center',
   'Private or independent practice – national network or shared services network (e.g. US -- Oncology, AON, OneOncology, QCCA, NCCA)',
   'University hospital, academic center, or teaching program',
   'Other (Fill in the Blank)'
   ]
   return orgType
  }


  export const dataOrigin = () => {
      return [ 'NPI',
         'Listserv',
         'Patient Point',
         'COPA WEB MEMBER<',
         'Email',
         'BOARD MEMBER',
         'OCM MEMBER',
         'IQVIA',
         'CAN MEMBER',
         'COC2019',
         'ADVISORY COMMITTEE MEMBER',
         'BOARD EXEC COMMITTEE'
         ]
}

export const currentRole = () => {
   return ['Administrative Support Staf',
            'Administrator',
            'Advanced Practice Provider',
            'Clinical Research Manager/Coordinator',
            'Consultant',
            'Fellow',
            'Financial Navigator or Financial Counselor',
            'Management',
            'Medical Assistant',
            'Medical Biller or Coder',
            'Non-Profit Association or Advocacy Group Staff',
            'Nurse',
            'Patient/Survivor/Family Advocate',
            'Pharmaceutical/Manufacturer Representative',
            'Pharmacist',
            'Pharmacy Technician',
            'Physician - Oncologist and/or Hematologist (MD)',
            'Physician - Radiation Oncologist (MD)',
            'Physician – Urologist (MD)',
            'Senior Leadership or Management',
            'Social Worker',
            'State Oncology Society Staff',
            'State Society Executive',
            'Type',
            'Other']
   }


// Generate Resource Links Function
export const getResourceLinks = async (slugs) => {
   var result = []
   if (slugs.length > 2) {
      const parents = slugs.filter(item => item.parent === '63ca534c72155783cb74ee26').map(parent => ({
         ...parent,
         link: `/${parent.slug}`
      }));
      result = parents.map(parent => ({
         ...parent,
         children: slugs
            .filter(item => item.parent === parent._id)
            .map(child => ({
               ...child,
               link: `/${parent.slug}/${child.slug}`
            }))
      }));
   } else {
      result = [
         {
            _id: slugs?.[0]?._id,
            slug: slugs?.[0]?.slug,
            name: slugs?.[0]?.name,
            parent: slugs?.[0]?.parent,
            link: `/${slugs?.[0]?.slug}`,
            children: [
               {
                  _id: slugs?.[1]?._id,
                  slug: slugs?.[1]?.slug,
                  name: slugs?.[1]?.name,
                  parent: slugs?.[1]?.parent,
                  link: `/${slugs?.[0]?.slug}/${slugs?.[1]?.slug}`
               }
            ]
         }
      ]
   }
   return result
}